import React, { Component } from "react";
import { userActions } from "../../_actions";
import { connect } from "react-redux";
import "jspdf-autotable";
import LoadingOverlay from "react-loading-overlay";
import "./styles.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Papa from "papaparse";
import moment from "moment";
import ReactECharts from 'echarts-for-react';
import { confirmAlert } from "react-confirm-alert";

class MainPTwoMissComb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      listSuccess: [],
      errorsUser: {},
      fieldsUser: {},
      input1: "",
      input2: "",
      currentPage: 1,
      pageSize: 10,
    };
  }

  componentDidMount() {
    // this.props.dispatch(userActions.clearDataEVM());
    // this.props.dispatch(userActions.clearData());
    const resData2 = {
      id: this.props.match.params.id,
      keyWord: "",
      pageNo: this.state.currentPage,
      sortBy: "createdAt",
      sortOrder: "desc",
      size: this.state.pageSize,
    };
    this.props.dispatch(
      userActions.getMainPTwoMissCombList(resData2)
    );
    this.props.dispatch(userActions.showMainPhrasesProgressCombBar({ validPassphraseId: this.props.match.params.id }));
    this.interval = setInterval(() => this.loadData(), 10000);
    window.scrollTo(0, 0);
  }


  loadData = () => {
    this.props.dispatch(userActions.showMainPassPhrasesProgressBar({ validPassphraseId: this.props.match.params.id }));
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.savePhraseSuccess) {
      return {
        ...nextProps,
        errorsUser: {},
        fieldsUser: {},
      };
    } else {
      return {
        ...nextProps,

      };
    }
  }

  handleCloseModal = () => {
    this.props.dispatch(userActions.clearData());
    this.setState({ errorsUser: {}, fieldsUser: {} });
  };

  // handleInputChange = (e) => {
  //   this.setState({ [e.target.name]: e.target.value });
  // }
  // handleInputChange = (e) => {
  //   e.preventDefault();
  //   let { name, value } = e.target;
  //   // this.setState({ [name]: value });
  //   let fieldsUser = this.state.fieldsUser;
  //   let errorsUser = this.state.errorsUser;
  //   fieldsUser[name] = value;
  //   errorsUser[name] = "";
  //   // console.log(name, value);
  //   this.setState({ fieldsUser, errorsUser });
  // };
  submitHandle = () => {
    // console.log('');
    if (this.validationCheck()) {
      const { passPhrase, index } = this.state.fieldsUser;
      const resData = {
        incompleteMnemonic: passPhrase,
        // replaceIndex: index
      };
      const pageNation = {
        keyWord: "",
        pageNo: 1,
        sortBy: "createdAt",
        sortOrder: "desc",
        size: 20,
      };

      this.props.dispatch(userActions.getValidPassPhrasesWithAddress(resData, pageNation));
    }
  };
  validationCheck = () => {
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = {};
    let valid = true;

    if (!fieldsUser["passPhrase"] || fieldsUser["passPhrase"] == "") {
      errorsUser["passPhrase"] = `PassPhrase can't be empty.`;
      valid = false;
    }
    this.setState({ errorsUser: errorsUser });
    return valid;
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      let offset = Math.ceil((this.state.currentPage - 1) * this.state.pageSize);
      this.setState({ offset: offset });
      const resData2 = {
        id: this.props.match.params.id,
        keyWord: "",
        pageNo: this.state.currentPage,
        sortBy: "createdAt",
        sortOrder: "desc",
        size: this.state.pageSize,
      };
      this.props.dispatch(
        userActions.getMainPTwoMissCombList(resData2)
      );
    });
  };

  renderPagination = () => {
    const { mainPassphraseComb } = this.props.users;
    const { total } = mainPassphraseComb
      ? mainPassphraseComb
      : {};
    const { currentPage, pageSize } = this.state;
    const totalPages = total ? Math.ceil(total / pageSize) : 0;


    if (totalPages <= 1) return null;

    let pages = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    // Previous Button
    if (currentPage > 1) {
      pages.push(
        <button
          key="prev"
          onClick={() => this.handlePageChange(currentPage - 1)}
          className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
        >
          Previous
        </button>
      );
    }

    // Page Numbers
    if (startPage > 1) {
      pages.push(
        <span key="first" className="px-4 py-2 mx-1 rounded">
          1
        </span>
      );
      if (startPage > 2) {
        pages.push(
          <span key="dots-prev" className="px-2 py-2 mx-1 rounded">
            ...
          </span>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => this.handlePageChange(i)}
          className={`px-4 py-2 mx-1 rounded ${currentPage === i ? "bg-[#CB9731]" : "bg-[#2E2E4E]"
            }`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(
          <span key="dots-next" className="px-2 py-2 mx-1 rounded">
            ...
          </span>
        );
      }
      pages.push(
        <button
          key={totalPages}
          onClick={() => this.handlePageChange(totalPages)}
          className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
        >
          {totalPages}
        </button>
      );
    }

    // Next Button
    if (currentPage < totalPages) {
      pages.push(
        <button
          key="next"
          onClick={() => this.handlePageChange(currentPage + 1)}
          className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
        >
          Next
        </button>
      );
    }

    return <div className="flex justify-center mt-4">{pages}</div>;
  };
  navigatePhraseDetails = (data) => {
    this.props.history.push(`/app/mainPTowMissCombBalance/${data.id}`);
  };

  handleInputChange1 = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    // this.setState({ [name]: value });
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = this.state.errorsUser;
    fieldsUser[name] = value;
    errorsUser[name] = "";
    // console.log(name, value);
    this.setState({ fieldsUser, errorsUser });
  };

  handleInputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    // this.setState({ [name]: value });
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = this.state.errorsUser;
    fieldsUser[name] = value;
    errorsUser[name] = "";
    this.setState({ currentPage: 1 })
    const resData = {
      // id: this.props.match.params.id,
      // // isBalance: this.state.isBalance,
      // keyWord: value ? value : "",
      // pageNo: 1,
      // sortBy: "createdAt",
      // sortOrder: "desc",
      // size: Number(this.state.pageSize),

      id: this.props.match.params.id,
      keyWord: value ? value : "",
      pageNo: this.state.currentPage,
      sortBy: "createdAt",
      sortOrder: "desc",
      pageNo: 1,
      size: Number(this.state.pageSize)
    };

    // console.log("this.props.match.params.id", this.props.match.params.id)
    if (this.props.match.params.id) {
      this.props.dispatch(userActions.getMainPTwoMissCombList(resData));
    }

  };

  getChartOption() {
    let { users } = this.props;
    const { passPhrasesProgressBarDetails3 } = users;
    let { currentCount, totalCount } = passPhrasesProgressBarDetails3 ? passPhrasesProgressBarDetails3 : {};

    // let totalCountSeries = [];
    let currentCountResult = null;

    // if (passPhrasesProgressBarDetails3) {
    //   let obj = currentCount[0];
    //   for (let key in obj) {
    //     if (obj.hasOwnProperty(key)) {
    //       totalCountSeries.push(totalCount);
    //     }
    //   }
    // }
    // series Wise currentCount 
    if (passPhrasesProgressBarDetails3) {
      currentCountResult = [
        passPhrasesProgressBarDetails3.total,
        passPhrasesProgressBarDetails3.pendingPass,
        passPhrasesProgressBarDetails3.resolvePass,
      ];
    }


    // console.log('totalCountSeries????yyyyyyyyyyyyyyy', totalCountSeries);
    console.log('currentCountResult????yyyyyyyyyyyyyyy', currentCountResult);
    // console.log('currentCount????yyyyyyyyyyyyyyy', currentCount);


    return {
      title: {
        text: 'PassPhrase Progress Bar',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {},
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
      },
      yAxis: {
        type: 'category',
        data: ['TOTAL', 'PENDING', 'RUNING'],
      },
      series: [
        {
          name: 'Total',
          type: 'bar',
          // data: totalCountSeries,
        },
        {
          name: 'current',
          type: 'bar',
          data: currentCountResult,
        },
      ],
    };
  }

  deleteValidPassPhrases = (data) => {
    const dataRes = {
      id: this.props.match.params.id,
      keyWord: "",
      pageNo: this.state.currentPage,
      sortBy: "createdAt",
      sortOrder: "desc",
      size: this.state.pageSize,
    };

    const obj = { id: data.id };

    confirmAlert({
      title: 'Confirm to Delete Pass Phrase?',
      message: `Are you sure you want to delete the pass phrase?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.deleteMainPassphraseComb(obj, dataRes))
        },
        {
          label: 'No'
        }
      ]
    });
  };

  handlePageChange1 = () => {
    if (this.validation()) {
      const pageNumber = this.state.fieldsUser?.['pageNo'] ? Number(this.state.fieldsUser['pageNo']) : null;
      const { mainPassphraseComb } = this.props.users;
      const { total } = mainPassphraseComb || {};
      const { currentPage, pageSize } = this.state;


      const totalPages = total ? Math.ceil(total / pageSize) : 0;



      console.log('Total pages:', totalPages);

      if (totalPages <= 1) return null;

      let pages = [];
      const startPage = Math.max(1, pageNumber - 2);
      const endPage = Math.min(totalPages, pageNumber + 2);

      if (startPage > 1) {
        pages.push(
          <span key="first" className="px-4 py-2 mx-1 rounded">
            1
          </span>
        );
        if (startPage > 2) {
          pages.push(
            <span key="dots-prev" className="px-2 py-2 mx-1 rounded">
              ...
            </span>
          );
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <button
            key={i}
            onClick={() => this.handlePageChange(i)}
            className={`px-4 py-2 mx-1 rounded ${currentPage === i ? "bg-[#CB9731]" : "bg-[#2E2E4E]"
              }`}
          >
            {i}
          </button>
        );
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          pages.push(
            <span key="dots-next" className="px-2 py-2 mx-1 rounded">...</span>
          );
        }
        pages.push(
          <button
            key={totalPages}
            onClick={() => this.handlePageChange(totalPages)}
            className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
          >
            {totalPages}
          </button>
        );
      }

      // const pageNumber = this.state && this.state.fieldsUser && this.state.fieldsUser['pageNo']
      pages.push(
        this.handlePageChange(pageNumber)
      )
      return <div className="flex justify-center mt-4">{pages}</div>;
    }
  };
  validation = () => {

    console.log("hello validation");

    let fieldsUser = this.state.fieldsUser;
    let errorsUser = {};
    let formIsValid = true;

    const { mainPassphraseComb } = this.props.users;
    const { total } = mainPassphraseComb
      ? mainPassphraseComb
      : {};

    const { currentPage, pageSize } = this.state;
    const totalPages = total ? Math.ceil(total / pageSize) : 0;

    //User Name
    if (!fieldsUser["pageNo"]) {
      formIsValid = false;
      errorsUser["pageNo"] = "Page number cannot be empty.";
    }
    if (fieldsUser["pageNo"] < 0) {
      formIsValid = false;
      errorsUser["pageNo"] = "Please enter positive number.";
    }
    if (fieldsUser["pageNo"] > totalPages) {
      formIsValid = false;
      errorsUser["pageNo"] = "You show only page last number data.";
    }

    this.setState({ errorsUser: errorsUser });
    return formIsValid;

  };

  render() {
    let { users } = this.props;
    const { passPhrase, index } = this.state.fieldsUser;
    const {
      loading,
      mainPassphraseComb,
      passPhrasesProgressBarDetails3
    } = users;

    console.log(
      "passPhrasesProgressBarDetails3",
      passPhrasesProgressBarDetails3
    );

    // let { currentCount, totalCount } = passPhrasesProgressBarDetails3 ? passPhrasesProgressBarDetails3 : {};

    let currentCountResult = null;

    if (passPhrasesProgressBarDetails3) {
      currentCountResult = [
        passPhrasesProgressBarDetails3.resolvePass,
        passPhrasesProgressBarDetails3.pendingPass,
        passPhrasesProgressBarDetails3.total
      ];
    }

    const { total } = mainPassphraseComb || {};
    const { pageSize } = this.state;


    const totalPages = total ? Math.ceil(total / pageSize) : 0;

    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text="Please wait..."
          />
        </div>

        <div className="relative z-0 flex-1 overflow-y-auto focus:outline-none bg_page">
          <section>
            <div className="overflow-hidden">
              <div className="overflow-y-auto">

                <div className="w-full px-7 py-7 dashboard-card rounded-xl divide-y-[1px] divide-white/30">
                  <div className="w-full">
                    <ReactECharts option={this.getChartOption()} />
                  </div>
                </div>

                <div className="p-8 space-y-3 ">

                  <div className=" w-[35rem] mx-auto ">
                    <label className="text-[#CB9731]">Search</label>
                    <div className="my-2">
                      <input
                        type="text"
                        id="passPhrase"
                        name="passPhrase"
                        value={passPhrase ?? ""}
                        onChange={this.handleInputChange}
                        className="w-full p-2 border rounded"
                        placeholder="Enter Here"
                      />
                      {this.state.errorsUser["passPhrase"] ? (
                        <div className="text-xs italic text-red-500 invalid-feedback">
                          {this.state.errorsUser["passPhrase"]}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <section className="rounded-lg">
                    <div className="max-w-full overflow-auto">

                      <div className="text-xl font-bold text-center text-white">
                      </div>
                      <div className="inline-block min-w-full">
                        <section className="rounded-lg">
                          <div className="max-w-full overflow-auto">
                            <div className="inline-block min-w-full">
                              <table className="min-w-full bg-[#24243C] rounded-lg">
                                <thead className="text-center text-white uppercase ">
                                  PassPhrases
                                  <tr className="text-white bg-[#2E2E4E] text-sm font-semibold text-center uppercase rounded-t-lg">
                                    <th className="px-3 py-3 whitespace-nowrap">
                                      S/n
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      createdAt
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      passphrases
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {mainPassphraseComb &&
                                    mainPassphraseComb.list &&
                                    mainPassphraseComb.list.length >
                                    0
                                    ? mainPassphraseComb.list.map(
                                      (element, index) => (
                                        <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            {this.state.offset + index + 1}
                                          </td>
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            {element && element.createdAt
                                              ? moment(
                                                element.createdAt
                                              ).format("DD-MM-YYYY HH:mm")
                                              : null}
                                          </td>
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            {element && element.passPhrase
                                              ? element.passPhrase
                                              : "-"}
                                          </td>
                                          <td className="gap-2 px-3 py-3 whitespace-nowrap">
                                            <span
                                              onClick={() =>
                                                this.navigatePhraseDetails(
                                                  element
                                                )
                                              }
                                              className="bg-[#20BFA9] hover:bg-[#B5862B] px-4 py-2 rounded text-white cursor-pointer mr-2"
                                            >
                                              More Details
                                            </span>
                                            <span
                                              onClick={() => this.deleteValidPassPhrases(element)}
                                              className="bg-[#bf2020] hover:bg-[#ca5252ab] px-4 py-2 rounded text-white cursor-pointer"
                                            >
                                              Delete
                                            </span>
                                          </td>
                                        </tr>
                                      )
                                    )
                                    : null}
                                </tbody>
                              </table>
                              <div className="w-full mx-auto flex justify-center items-center space-x-4 pb-6">
                                <div>
                                  {this.renderPagination()}
                                </div>
                                {totalPages > 1
                                  ? <>
                                    <input className="py-2 px-2 w-16 rounded-md mt-4" type="number" placeholder="P.No." onChange={this.handleInputChange1} name="pageNo" id="pageNo" value={this.state.fieldsUser && this.state.fieldsUser['pageNo'] ? this.state.fieldsUser['pageNo'] : ''} />
                                    <button className="py-2 mt-4 bg-white text-center rounded-md text-black w-20" onClick={() => this.handlePageChange1()}>Submit</button>
                                  </>
                                  : null
                                }
                              </div>

                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </section>
                </div>
              </div >
            </div >
          </section >
        </div >
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  console.log("usersusersusersusersusersusers", users);
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting,
  };
}
export default connect(mapStateToProps)(MainPTwoMissComb);
